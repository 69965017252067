export default class MaterialData {

    materialData_id: number = 0;
    materialData_materialId: number = 0;
    data_key: string = '';
    data_column: string = '';

    constructor(
        materialData?: MaterialData
    ) {
        if (materialData !== null && materialData !== undefined) {
            this.materialData_id = materialData.materialData_id;
            this.materialData_materialId = materialData.materialData_materialId;
            this.data_key = materialData.data_key;
            this.data_column = materialData.data_column;
        }
    }
}
