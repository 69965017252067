import React, {FunctionComponent} from 'react';
import LoginForm from '../components/user/loginForm';
import "./Login.css";

const Login: FunctionComponent = () => {
    return (
        <>
            <LoginForm/>
        </>
    );
}

export default Login;
