import React, { useEffect, useState } from 'react'
import SitesService from '../../services/sites-service'
import Moment from 'moment';
import PlanningChantier from './PlanningChantier';
const ContainerUniqSite = ({ match }) => {
    
    const sit = []
    const site_id = match.params.id
    const [site,setSite] = useState([])

    useEffect(()=>{
        const getSiteById = async ()=>{
            let resp = await SitesService.getSite(site_id)
            var rep = sit.push(resp.site)
            setSite(sit)
        }
        getSiteById()
    },[])
    useEffect(()=>{
        formatEvent()
    },[site])
    const events = [];
    const formatEvent = () => {
        if(site){

            site.map((s)=>{
                console.log(s)
                events.push({
                    title:s.site_number_site,
                    start:setDate(s.site_date_start),
                    end:setDate(s.site_date_end),
                    ressourceId:s.site_id
                  })
                  return 0
            })
        }
        
    }

    const setDate =(date) => {
        let myDate = Moment.unix(date);
        return myDate.format('YYYY-MM-DD hh:mm:ss');
    }
    return(
        <>
            <div className="hero-auto">
            <PlanningChantier eventsChantier={events} />
            </div>
        </>
    )
}

export default ContainerUniqSite