import Problem from "../models/Problem"
import ProblemData from "../models/ProblemData"
import { Url } from "../models/utils/Url"
import {generateHeaders} from "./auth-service";

export async function getAllProblems() {
    return await fetch(Url.GET_ALL_PROBLEM_URL, {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function getOneProblem(id: number) {
    return await fetch(Url.GET_PROBLEM_URL.replace(Url.ARG_PROBLEM_ID , id.toString()), {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function deleteOneProblem(id: number) {
    return await fetch(Url.DELETE_PROBLEM_URL.replace(Url.ARG_PROBLEM_ID , id.toString()), {
        mode: "cors",
        method: 'DELETE',
        headers: generateHeaders()
    })
        .then(response => response)
}

export async function updateOneProblem(problem: Problem) {
    return await fetch(Url.UPDATE_PROBLEM_URL	, {
        mode: "cors",
        method: 'PUT',
        body: JSON.stringify({"problem_id": problem.problem_id, "problem_ref": problem.problem_ref,}),
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function postOneProblem(problem: Problem) {
    return await fetch(Url.POST_PROBLEM_URL, {
        mode: "cors",
        method: 'POST',
        body: JSON.stringify({"problem_ref": problem.problem_ref}),
        headers: generateHeaders()
    })
        .then(response => response.json())
}


export async function postDataProblem(data: ProblemData) {
    console.log(data)
    return await fetch(Url.POST_PROBLEM_DATA_URL, {
        mode: "cors",
        method: 'POST',
        body: JSON.stringify({
            "problemData_problemId": data.problemData_problemId,
            "problemData_key": data.problemData_key,
            "problemData_column": data.problemData_column
        }),
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function getAllProblemData() {
    return await fetch("http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/problem/data/getAll", {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    })
        .then(response => response.json())
}



export async function updateOneDataProblem(data: ProblemData) {
    return await fetch(Url.UPDATE_PROBLEM_DATA_URL, {
        mode: "cors",
        method: 'PUT',
        body: JSON.stringify({
            "problemData_id": data.problemData_id,
            "problemData_problemId": data.problemData_problemId,
            "problemData_key": data.problemData_key,
            "problemData_column": data.problemData_column
        }),
        headers: generateHeaders()
    })
        .then(response => response.json())
}
  
