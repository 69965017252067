export class DateDetail {
    date: string = '';
    timezone_type: number = 0;
    timezone: string = '';

    constructor(date?: DateDetail) {
        if (date) {
            this.date = date.date;
            this.timezone_type = date.timezone_type;
            this.timezone = date.timezone;
        }
    }
}