import React, { FunctionComponent, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Problem from "../../models/Problem";
import ProblemData from "../../models/ProblemData";
import { getOneProblem } from "../../services/problems-service";
import { BrowserRouter, Link } from 'react-router-dom';
import {useHistory} from 'react-router';
import Users from "../../models/Users";

type Params = { id: string };

const DetailProblem: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
    
    const [name, setName] = useState<ProblemData>(new ProblemData());
    const [site, setSite] = useState<ProblemData>(new ProblemData());
    const [description, setDescription] = useState<ProblemData>(new ProblemData());
    const [problem, setProblem] = useState(new Problem());
    
    useEffect(() => {

        const getDataValue = (datas: ProblemData[]) => {
            if (datas.length !== 0) {
                for (const data of datas) {
                    if (data.problemData_key === 'Nom') {
                        setName(data);
                    }
                    if (data.problemData_key === 'Description') {
                        setDescription(data);
                    }
                    if (data.problemData_key === 'Site') {
                        setSite(data);
                    }
                }
            } else {
                name.problemData_problemId = problem.problem_id;
                setName(name)
                description.problemData_problemId = problem.problem_id;
                setDescription(description);
                site.problemData_problemId = problem.problem_id;
                setSite(site);
                datas.push(name);
                datas.push(description);
                datas.push(site)
            }
        }

        getOneProblem(+match.params.id).then((e) => {
            setProblem(e);
            if (e.data) {
                getDataValue(e.data);
            }
        });
    }, [match.params.id]);
    console.log(problem);

    
        return (
            <div className="hero">
                <div className='heroProblemeEnfant'>
                    <div className="container mt-5 pt-5 justify-content-center d-flex align-items-center h-100">
                    <form className="mt-5 " >
                        <div className="card hoverable" id="cardGeneral">
                        <h2 className="text-center mb-4 mt-2"> Détail du Ticket</h2>
                            <div className="card-stacked">
                                <div className="card-content">
                                            <div className="form-group">
                                                <label htmlFor="lastname" className="text-secondary h6">Nom du Ticket </label>
                                                <input type="text" className="form-control" name="lastname" value={name.problemData_column}/>
                                            </div>

                                            <div className="form-group">
                                        <label  className="text-secondary h6">Site associé</label>
                                        <input  type="number" className="form-control" value={site.problemData_column} />
                                        </div>

                                        <div className="form-group">
                                        <label  className="text-secondary h6">Référence du Ticket</label>
                                        <input  type="number" className="form-control" value={problem.problem_ref} />
                                        </div>

                                        <div className="form-group">
                                        <label  className="text-secondary h6">Description du Ticket</label>
                                        <input type="text" className="form-control" value={description.problemData_column}/>
                                        </div>
                                </div>
                            </div>
                        </div>
                        </form>
            <Link className="btn-floating btn-large waves-effect waves-light orange lighten-1 z-deepth-3"
                style={{ position: 'fixed', top: '95px', left: '50px' }}
                to="/problems">
                <i className="material-icons">navigate_before</i>
            </Link>
                    </div>
                </div>
            </div>
        )
 



    
}
export default DetailProblem;
