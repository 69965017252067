import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css'

const TravauxServices: FunctionComponent = () => {  

    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">Les travaux services</h1>           
                <section className="mt-3 pt-5">
                    <div className="container">
                        <div className="column m-0 justify-content-center mb-5">            
                            <div className="col-lg-12 align-items-center d-flex flex-column mb-3 mt-2">  
                                <h5 className="d-flex justify-content-center mb-4">L’activité travaux & services d’Heimdall Construction propose aux clients publics 
                                et privés des prestations pour l’entretien dans la durée de tous les types d’ouvrages.</h5>                         
                                <p className="textAlign">L’activité travaux & services d’hemdall Construction connaît une croissance soutenue depuis sa création 
                                car elle répond à une difficulté : réunir et coordonner des PME ou des artisans du bâtiment pour des interventions de quelques semaines.
                                Elle utilise les méthodes de l’entreprise générale pour chiffrer, planifier et exécuter ces interventions. Les équipes pluridisciplinaires 
                                d’heimdall travaux & services interviennent pour la maintenance, la réhabilitation, la rénovation ou encore la mise en conformité réglementaire 
                                des bâtiments, avec une intervention tout corps d’état (TCE) en site libre ou occupé. Les clients bénéficient à la fois de la souplesse d’une 
                                PME et de la puissance et de l’expertise d’un grand groupe. Pour tous les clients – collectivités, entreprises ou particuliers – c’est la garantie 
                                d’une même qualité de service.</p> 

                                <p className="textAlign">Heimdall Construction exerce cette activité via des contrats de maintenance pluriannuels, des marchés à bons de commande ou encore des microchantiers ponctuels.
                                Grâce à un maillage territorial dense, les collaborateurs du réseau interviennent partout en France avec une grande réactivité.
                                Un suivi personnalisé est mis en place avec un accompagnement à chaque étape du chantier, qui permet d’établir une relation de confiance durable.</p>                         
                            </div>  
                            <div className="col-lg-12" id='ImgPageTravauxServices'>                                                       
                            </div> 
                        </div>                   
                    </div>
                </section>                     
        </div>

        





    );
}

export default TravauxServices;