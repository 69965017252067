import React, {FunctionComponent, useState, useEffect} from "react";
import Sites from "../../models/sites";
import SitesCard from "./sites-card";
import SitesService from "../../services/sites-service";
import "../../pages/sites.css";
import {Link} from "react-router-dom";
import {GetRole} from "../../models/role";
import {Session} from "../../services/auth-service";

const SitesList: FunctionComponent = () => {
    const [sites, setSites] = useState<Sites[]>([]);

    useEffect(() => {
        if (Session().role_id === GetRole.DIRECTOR) {
            SitesService.getAllSites().then((sites) => {
                setSites(sites);
            });
        } else {
            SitesService.getSiteByUser(Session().id).then((sites) => setSites(sites));
        }
    }, []);

    return (
        <div className="heroSites">
            <div className="heroSitesEnfant">
                <h1 className="center text-light">Liste des chantiers</h1>
                <div className="container">
                    <div className="row">
                        <Link className="btn-floating btn-large waves-effect waves-light green z-deepth-3"
                              style={{position: 'fixed', top: '90px', right: '25px'}}
                              to="/sites/add">
                            <i className="material-icons">add</i>
                        </Link>
                        {sites && sites.length !== 0 ?
                            sites.map(site => (
                                <SitesCard key={site.site_id} site={site}/>
                            )) : <SitesCard key={1} site={new Sites()}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitesList;
