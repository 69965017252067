import ProblemData from "./ProblemData";
import {DateDetail} from "./utils/DateDetail";

export default class Problem {

    problem_id: number;
    problem_ref: string;
    created_at: DateDetail;
    updated_at: DateDetail|null;
    deleted_at: DateDetail|null;
    data?: ProblemData[]|null;

    constructor(
        problem_id: number = -1,
        problem_ref: string = "",
        created_at: DateDetail  = new DateDetail(),
        updated_at: DateDetail|null = null,
        deleted_at: DateDetail|null = null,
        data: ProblemData[]|null = null
    )
{
        this.problem_id = problem_id;
        this.problem_ref = problem_ref;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
        this.data = data;
    }
}