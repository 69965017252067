export default class UsersData {

    userData_id: number = 0;
    userData_userId: number = 0;
    userData_key: string = '';
    userData_column: string = '';

    constructor(
        userData?: UsersData
    )
    {
        if (userData !== null && userData !== undefined) {
            this.userData_id = userData.userData_id;
            this.userData_userId = userData.userData_userId;
            this.userData_key = userData.userData_key;
            this.userData_column = userData.userData_column;
        }
    }

}
