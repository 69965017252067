import Materials from "../models/Materials";
import MaterialData from "../models/MaterialData";
import {Url} from "../models/utils/Url";
import {generateHeaders} from "./auth-service";


export async function getAllMaterial() {

    return await fetch(Url.GET_ALL_MATERIALS_URL, {
        mode: "cors", headers:
            generateHeaders()
    })

        .then(response => response.json())

}


export async function getMaterialCata(id: number) {
    return await fetch("http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/material/data/getCat/" + id, {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function getAllMaterialCategory() {

    return await fetch(Url.GET_ALL_MATERIALS_CATEGORY_URL, {
        mode: "cors",
        method: 'GET',
        headers:
            generateHeaders()
    })

        .then(response => response.json())
}

export async function getFullMaterials(): Promise<Materials[]> {
    return await fetch(Url.GET_ALL_MATERIALS_URL, {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    }).then(response => response.json());
}

export async function getOneMaterialWithData(id: number): Promise<Materials> {
    return await fetch('http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/material/getDetail/' + id, {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    }).then(response => response.json());
}

export async function getMaterialById(id: number) {
    return await fetch(Url.GET_MATERIAL_DETAIL_URL.replace(Url.ARG_MATERIAL_ID, id.toString()), {
        mode: "cors",
        method: 'GET',
        headers:
            generateHeaders()
    })
        .then(response => response.json())
}


export async function postMaterials(material: Materials): Promise<Materials> {
    return await fetch(Url.POST_MATERIAL_URL, {
        mode: "cors", method: "POST", body: JSON.stringify({"material_ref": material.material_ref}),
        headers:
            generateHeaders()
    }).then(response => response.json());
}


export async function deleteOneMaterial(id: number) {
    return await fetch(Url.DELETE_MATERIAL_URL.replace(Url.ARG_MATERIAL_ID, id.toString()), {
        mode: "cors",
        method: 'DELETE',
        headers: generateHeaders()
    })
        .then()
}

export async function updateOneMaterial(material: Materials) {
    return await fetch(Url.PUT_MATERIAL_URL, {
        mode: "cors",
        method: 'PUT',
        body: JSON.stringify({"material_id": material.material_id, "material_ref": material.material_ref,}),
        headers: generateHeaders()
    })
        .then(response => response.json())
}


////////DATA

export async function postDataMaterial(data: MaterialData) {
    return await fetch(Url.POST_MATERIAL_DATA_URL, {
        mode: "cors",
        method: 'POST',
        body: JSON.stringify({
            "materialData_materialId": data.materialData_materialId,
            "data_key": data.data_key,
            "data_column": data.data_column
        }),
        headers: generateHeaders()
    })
        .then(response => response.json())
}


export async function getAllMaterialData(id: number) {
    return await fetch("http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/material/data/getAll/" + id, {
        mode: "cors",
        method: 'GET',
        headers: generateHeaders()
    })
        .then(response => response.json())
}

export async function updateOneMaterialData(data: MaterialData) {
    return await fetch(Url.PUT_MATERIAL_DATA_URL, {
        mode: "cors",
        method: 'PUT',
        body: JSON.stringify({
            "materialData_id": data.materialData_id,
            "materialData_materialId": data.materialData_materialId,
            "data_key": data.data_key,
            "data_column": data.data_column
        }),
        headers: generateHeaders()
    })
        .then(response => response.json())
}
