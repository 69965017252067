export enum JobEmployee {
    UNDEFINED,
    ELECTRICIAN,
    PLUMBER,
    ROOFER,
    MASON,
    CARPENTER
}

export class JobEmployeeUtils {

    public static getJobArray(): JobEmployee[] {
        return [
            JobEmployee.ELECTRICIAN,
            JobEmployee.PLUMBER,
            JobEmployee.ROOFER,
            JobEmployee.MASON,
            JobEmployee.CARPENTER
        ];
    }

    public static getTextJob(job: JobEmployee): string {
        switch (job) {
            case JobEmployee.UNDEFINED:
                return 'Indéfini';
            case JobEmployee.ELECTRICIAN:
                return 'Electricien';
            case JobEmployee.CARPENTER:
                return 'Menuisier';
            case JobEmployee.PLUMBER:
                return 'Plombier';
            case JobEmployee.MASON:
                return 'Maçon';
            case JobEmployee.ROOFER:
                return 'Couvreur';
        }
    }
}