import React, {FunctionComponent} from 'react';
import {BrowserRouter as Router, Link, Route, Switch, useHistory, Redirect} from 'react-router-dom';
import {useUser} from "../contexts/userContext";
import Home from "../pages/Home";
import Login from "../pages/Login";
import EstimateAdd from "../pages/estimate-add";
import TaskList from "./planning/TasksList";
import CheckProfile from "../pages/CheckProfile";
import MaterialDetail from "./material/MaterialDetail";
import SitesList from "./site/Sites-list";
import SiteDetail from "./site/Site-detail";
import SitesAdd from "../pages/sites-add";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import CheckProblems from "../pages/CheckProblems";
import UpdateProblem from "./problem/UpdateProblem";
import DetailProblem from './problem/DetailProblem';
import CreateMaterial from "./material/CreateMaterial";
import CreateProblem from "./problem/CreateProblem";
import logo from "../assets/img/HeimdallContructionSansFond.png"
import SiteEdit from "./site/site-edit";
import CreateUser from './user/CreateUser';
import UpdateUser from './user/UpdateUser';
import updateMaterial from './material/UpdateMaterial';
import AddTask from './planning/AddTask';
import ContainerModifyTask from './planning/containerModifyTask';
import {GetRole} from "../models/role";
import {deleteSession, Session, TokenValidity} from "../services/auth-service";
import Dashboard from '../pages/dashboard';
import ContainerPlanningChantier from '../pages/ContainerPlanningChantier';
import ContainerUniqSite from './planning/ContainerUniqSite';
import GrosOeuvre from '../pages/grosOeuvre';
import ServiceAdapteConstBat from '../pages/serviceAdapteConstBat';
import ServiceAdapteGenieCivil from '../pages/serviceAdapteGenieCivil';
import ServiceAdapteBureauEtude from '../pages/serviceAdapteBureauEtude';
import ServiceAdapteMissionContr from '../pages/serviceAdapteMissionContr';
import TravauxServices from '../pages/travauxServices';

const Header: FunctionComponent = () => {
    const userContext = useUser();
    const onClickLogOut = () => {
        deleteSession();
        if (userContext) {
            userContext!.dispatch({type: "setUser", payload: {id: undefined, role: undefined}});
        }
    }

    const checkRole = (role: GetRole) => {
        switch (role) {
            case GetRole.DIRECTOR:
            case GetRole.MANAGER:
                return true;
            case GetRole.CUSTOMER:
            case GetRole.EMPLOYEE:
            case GetRole.UNDEFINED:
            default:
                return false;
        }
    }
    
    // display none the header if user scroll down
    const [isVisible, setIsVisible] = React.useState(true);
    const handleScroll = () => {
        const nav = document.getElementById("navv");
        //console.log(nav);
        if (window.scrollY > 1150) {
            setIsVisible(false);
            //console.log("scroll down");
            // add class to navbar to id nav 
            nav!.classList.add("navbarScroll");

        } else {
            setIsVisible(true);
            //console.log("scroll up");
            // remove class to navbar
            nav!.classList.remove("navbarScroll");
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <Router>
            <div className="">
                <Navbar expand="lg" id="navv">
                    <Container fluid>
                        <Navbar.Brand> <Link to="/"><img src={logo} alt="logo" id="logoNav"/> </Link></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll"/>
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0" id="navbarScroll" navbarScroll >
                                <Nav.Link> <Link to="/">Accueil</Link></Nav.Link>
                                {/* <Nav.Link> <Link to="/ServiceAdapte">Un service adapté</Link></Nav.Link> */}

                                {!TokenValidity() ? <NavDropdown title="Un service adapté" id="nav-dropdown">
                                    <NavDropdown.Item eventKey="4.1"><Link to="/ServiceAdapteConstBat" className="">Construction de Bâtiment</Link></NavDropdown.Item>                                   
                                    <NavDropdown.Item eventKey="4.2"><Link to="/ServiceAdapteGenieCivil" className="">Ouvrage de génie civil</Link></NavDropdown.Item>                                  
                                    <NavDropdown.Item eventKey="4.3"><Link to="/ServiceAdapteBureauEtude" className="">Bureau d'études</Link></NavDropdown.Item>                                   
                                    <NavDropdown.Item eventKey="4.4"><Link to="/ServiceAdapteMissionContr" className="">Mission de contrôle</Link></NavDropdown.Item>
                                </NavDropdown> : ''}

                                {!TokenValidity() ? <Nav.Link> <Link to="/GrosOeuvre">Le gros oeuvre</Link></Nav.Link> : ''}
                                {!TokenValidity() ? <Nav.Link> <Link to="/TravauxServices">Les travaux services</Link></Nav.Link> : ''}
                                {TokenValidity() ? <Nav.Link> <Link to="/profile" className="">Profil</Link></Nav.Link>  : ''}
                                <Nav.Link> <Link to="/devis">Devis</Link></Nav.Link>
                                {/* {TokenValidity() && checkRole(Session().role_id)  ? <Nav.Link> <Link to="/Dashboard">Dashboard</Link></Nav.Link> : ''}
                                {TokenValidity() && checkRole(Session().role_id) ? <Nav.Link> <Link to="/material" className="">Matériel</Link></Nav.Link> : ''} */}

                                 {TokenValidity() ? <NavDropdown title="Chantiers" id="nav-dropdown">
                                    <NavDropdown.Item eventKey="4.1"><Link to="/sites" className="">Liste Chantiers</Link></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="4.2"><Link to="/planningChantier" className="">Planning Chantiers</Link></NavDropdown.Item>
                                </NavDropdown> : ''}

                                
                                {/* {TokenValidity() && checkRole(Session().role_id) ? <Nav.Link><Link to="/problems" className="">Ticket</Link></Nav.Link> : ''} */}
                                {TokenValidity() ? <Nav.Link><Link to="/planning" className="">Agenda</Link></Nav.Link> : ''}
                            </Nav>
                            {!TokenValidity() ? 
                                <Nav className="d-flex justify-content-end"> 
                                    <Nav.Link> <Link to="/login" className="">Connexion</Link></Nav.Link>
                                </Nav> : ''}
                            {TokenValidity() ?
                                <Nav className="d-flex justify-content-end">
                                    <Nav.Link><Link to="/" onClick={() => onClickLogOut()}>Déconnexion</Link></Nav.Link>
                                </Nav> : '' }
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                {
                    TokenValidity() ?
                        checkRole(Session().role_id) ?
                            <Switch>
                                <Route exact path="/planning" component={TaskList}/>
                                <Route exact path="/planning/add" component={AddTask}/>
                                <Route exact path="/planning/modify/:id" component={ContainerModifyTask}/>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/profile" component={CheckProfile}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/devis" component={EstimateAdd}/>
                                <Route exact path="/material" component={MaterialDetail}/>
                                <Route exact path="/sites" component={SitesList}/>
                                <Route exact path="/problems" component={CheckProblems}/>
                                <Route exact path='/sites/search/:id' component={SiteDetail}/>
                                <Route exact path='/CreateMaterial' component={CreateMaterial}/>
                                <Route exact path='/problems/create/' component={CreateProblem}/>
                                <Route exact path='/problems/update/:id' component={UpdateProblem}/>
                                <Route exact path='/problems/detail/:id' component={DetailProblem}/>
                                <Route exact path='/sites/add' component={SitesAdd}/>
                                <Route exact path='/sites/edit/:id' component={SiteEdit}/>
                                <Route exact path='/CreateUser' component={CreateUser}/>
                                <Route exact path='/users/update/:id' component={UpdateUser}/>
                                <Route exact path='/material/update/:id' component={updateMaterial}/>
                                <Route exact path='/Dashboard' component={Dashboard}/>
                                <Route exact path='/planningChantier' component={ContainerPlanningChantier} />
                                <Route exact path="/planningSite/uniq/:id" component={ContainerUniqSite} />
                                <Redirect to="/"/>
                            </Switch>
                            :
                            <Switch>
                                <Route exact path="/planning" component={TaskList}/>
                                <Route exact path="/planning/add" component={AddTask}/>
                                <Route exact path="/planning/modify/:id" component={ContainerModifyTask}/>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/profile" component={CheckProfile}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/devis" component={EstimateAdd}/>
                                <Route exact path="/sites" component={SitesList}/>
                                <Route exact path='/sites/search/:id' component={SiteDetail}/>
                                <Route exact path='/sites/add' component={SitesAdd}/>
                                <Route exact path='/sites/edit/:id' component={SiteEdit} />
                                <Route exact path='/CreateUser' component={CreateUser}/>
                                <Route exact path='/users/update/:id' component={UpdateUser}/>
                                <Route exact path='/planningChantier' component={ContainerPlanningChantier} />
                                <Route exact path="/planningSite/uniq/:id" component={ContainerUniqSite} /> 
                                <Redirect to="/"/>                              

                            </Switch>
                            :
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/devis" component={EstimateAdd}/>
                                <Route exact path='/serviceAdapteConstBat' component={ServiceAdapteConstBat}/>
                                <Route exact path='/serviceAdapteGenieCivil' component={ServiceAdapteGenieCivil}/>
                                <Route exact path='/serviceAdapteBureauEtude' component={ServiceAdapteBureauEtude}/>
                                <Route exact path='/serviceAdapteMissionContr' component={ServiceAdapteMissionContr}/>
                                <Route exact path='/GrosOeuvre' component={GrosOeuvre}/>
                                <Route exact path='/TravauxServices' component={TravauxServices}/>
                                <Redirect to="/"/>
                            </Switch>
                }
            </div>
        </Router>
    );
}


export default Header;
