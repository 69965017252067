import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css'
import Fade from 'react-reveal/Fade';

const ServiceAdapteBureauEtude: FunctionComponent = () => {

     

    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">Le bureau d'étude</h1>           
                <section className="mt-5 pt-5">
                    <div className="container">
                        <div className="row m-0 justify-content-center mb-5">
                            <div className="col-lg-4 align-items-center d-flex flex-column mb-3 mt-2">                            
                                <p className="textAlign">Heimdall Construction est aussi un bureau d’études structures et d’ingénierie ayant une expertise 
                                technique dans le domaine du bâtiment et du génie civil. Nous proposons un service de qualité et de proximité au 
                                service de la construction dans les domaines suivants :
                                    <ul>
                                        <li>• Etudes de structure Béton Armé et de Charpente Métallique</li>
                                        <li>• Maitrise d’œuvre de conception et d’exécution</li>
                                        <li>• Etudes géotechniques et topographiques</li>
                                        <li>• Diagnostic structurel, expertise : étude de faisabilité et renforcement de structure, réhabilitation et/ou réaménagement,</li>
                                        <li>• Diagnostic sécurité incendie et accessibilité « handicapés »</li>
                                        <li>• Diagnostic des installations techniques : Plomberie sanitaire et froid, Electricité (courant fort et faible), 
                                        Installations de sécurité incendie, etc.</li>
                                    </ul>
                                Nos principes :
                                    <ul>
                                        <li>• Optimisation des choix techniques</li>
                                        <li>• Fiabilité, Rapidité et Réactivité</li>
                                    </ul>
                                </p>                           
                            </div>
                            <Fade left>
                            <div className="col-6" id='ImgPageBureauEtude'>                                                       
                            </div>
                            </Fade>
                        </div>
                    </div>
                </section>                     
        </div>

        





    );
}

export default ServiceAdapteBureauEtude;