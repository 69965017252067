import Sites from '../models/sites';
import SitesData from '../models/sitesData';
import {GeocodeResponse} from "../models/geocodeResponse";
import {Url} from "../models/utils/Url";
import {SiteDetails} from "../models/utils/siteDetails";
import moment from "moment";
import {generateHeaders} from "./auth-service";

export default class SitesService {

    static async getAllSites(): Promise<Sites[]>{
        return await fetch(Url.GET_ALL_SITES_URL, {
            mode: "cors",
            method: 'GET',
            headers: generateHeaders()
        }).then(response => response.json());
    }

    static async getSite(site_id: number): Promise<SiteDetails> {
        return await fetch(Url.GET_SITE_SEARCH_URL.replace(Url.ARG_SITE_ID, site_id.toString()), {
            mode: "cors",
            method: 'GET',
            headers: generateHeaders()
        }).then(response => response.json());
    }

    static async getSiteData(site_id: number): Promise<SitesData[]> {
        return await fetch('http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/sites/data/' + site_id, {
            mode: "cors",
            method: 'GET',
            headers: generateHeaders()
        })
            .then(response => response.json())
    }

    static async getSiteByUser( user_id: number ): Promise<Sites[]> {
        return await fetch( Url.GET_SITE_BY_USER_URL.replace(Url.ARG_USER_ID, user_id.toString()), {
            mode: "cors",
            method: 'GET',
            headers: generateHeaders()
        } ).then( response => response.json());
    }


    static async postSite(site: Sites): Promise<Sites> {
        return await fetch(Url.POST_SITE_URL, {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                'site_number_site': site.site_number_site.toString(),
                'site_date_start': moment(site.site_date_start).unix(),
                'site_date_end': moment(site.site_date_end).unix()
            }),
            headers: generateHeaders()
        }).then(response => response.json());
    }

    static async postSiteData(siteData: SitesData) {
        return await fetch(Url.POST_SITE_DATA_URL, {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                'siteData_siteId': siteData.siteData_siteId.toString(),
                'siteData_key': siteData.siteData_key,
                'siteData_column': siteData.siteData_column
            }),
            headers: generateHeaders()
        }).then(response => response.json());
    }

    static async updateSite(site: Sites) {
        return await fetch(Url.PUT_SITE_URL, {
            method: 'PUT',
            mode: "cors",
            body: JSON.stringify({
                'site_id': site.site_id,
                'site_number_site': site.site_number_site,
                'site_date_start': site.site_date_start,
                'site_date_end': site.site_date_end
            }),
            headers: generateHeaders()
        }).then(response => response.json()).catch( (error) => this.handleError(error) );
    }

    static async deleteSite(id: number) {
        return await fetch(Url.DELETE_SITE_URL.replace(Url.ARG_SITE_ID, id.toString()), {
            method: 'DELETE',
            mode: 'cors',
            headers: generateHeaders()
        }).then(response => response.json());
    }

    static async updateData(siteData: SitesData) {
        return await fetch(Url.PUT_SITE_DATA_URL, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({
                'siteData_id': siteData.siteData_id,
                'siteData_key': siteData.siteData_key,
                'siteData_column': siteData.siteData_column
            }),
            headers: generateHeaders()
        }).then(response => response.json()).catch( (error) => this.handleError(error) );
    }

    static async getProblemBySite( site_id: number ) {
        return await fetch('http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1/problem/data/getBySite/' + site_id, {
            method: 'GET',
            mode: 'cors',
            headers: generateHeaders()
        } ).then( response => response.json() );
    }

    static async getAddressGouv(address: string): Promise<GeocodeResponse> {
        return await fetch( 'https://api-adresse.data.gouv.fr/search/?limit=5&q=' + address.replace(' ', '+'), {
            method: 'GET'
        } ).then( response => response.json());
    }


    static isEmpty(data: Object): boolean {
        return Object.keys(data).length === 0;
    }

    static handleError(error: Error): void {
        console.error(error);
    }
}

