import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css'
import Fade from 'react-reveal/Fade';

const ServiceAdapteConstBat: FunctionComponent = () => {

     

    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">La construction de bâtiment</h1>           
                <section className="mt-5 pt-5">
                    <div className="container d-flex">
                        <div className="column m-0 justify-content-center mb-5">
                            <div className="col-lg-12 align-items-center d-flex flex-column mb-3 mt-2">                            
                                <p className="textAlign">Heimdall Construction assure la conception-construction et la réhabilitation de tout 
                                type de bâtiments : Gros œuvre /clos couvert, Second œuvre, construction métallique, logements, 
                                bureaux, bâtiments fonctionnels ou industriels, reconnues pour son équipe pluridisciplinaire, son savoir-faire.</p>                           
                            </div>
                            <div className='row col-12 justify-content-center'>
                            <Fade left>
                                <div className="col-6" id='ImgPageconstbat'></div>
                                <div className="col-6 ms-5" id='ImgPageconstbat2'></div>
                                </Fade>
                            </div>
                        </div>        
                    </div>
                </section>                     
        </div>

        





    );
}

export default ServiceAdapteConstBat;