import React, {FunctionComponent, useEffect, useState} from "react";
import Materials from "../../models/Materials";
import MaterialData from "../../models/MaterialData";
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../../components/CreateMaterial.css';
import {
    getMaterialById,
    updateOneMaterial,
    updateOneMaterialData,
    postDataMaterial
} from "../../services/Materials-services";
import {RouteComponentProps} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router';
import {MaterialCategoryUtils} from "../../models/enums/materialCategory";


type Params = { id: string };

type Field = {
    value?: any,
    error?: string,
    isValid?: boolean
}
type Form = {
    ref: Field
}

const UpdateMaterial: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {

    const history = useHistory();

    const [material, setMaterial] = useState<Materials>(new Materials());
    const [dataName, setDataName] = useState<MaterialData>(new MaterialData());
    const [dataNumber, setDataNumber] = useState<MaterialData>(new MaterialData());
    const [dataCategory, setDataCategory] = useState<MaterialData>(new MaterialData());

    const [form, setForm] = useState<Form>({
        ref: {value: ''}
    });

    useEffect(() => {
        const getDataValue = (datas: MaterialData[]) => {
            if (datas.length !== 0) {
                for (const data of datas) {
                    if (data.data_key === 'name') {
                        setDataName(data);
                    }
                    if (data.data_key === 'number') {
                        setDataNumber(data);
                    }
                    if (data.data_key === 'categorie') {
                        setDataCategory(data);
                    }
                }
            } else {
                dataName.materialData_materialId = material.material_id;
                setDataName(dataName);
                dataNumber.materialData_materialId = material.material_id;
                setDataNumber(dataNumber);
                dataCategory.materialData_materialId = material.material_id;
                setDataCategory(dataCategory);
                datas.push(dataName);
                datas.push(dataNumber);
                datas.push(dataCategory);
            }
        }

        getMaterialById(+match.params.id).then((mat) => {
                setMaterial(mat);
                if (mat.data) {
                    getDataValue(mat.data);
                }
            }
        );
    }, [match.params.id]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        if (fieldName === 'ref') {
            material.material_ref = fieldValue;
        }
        const newField: Field = {[fieldName]: {value: fieldValue}};
        setForm({...form, ...newField});
    }

    const handleInputDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = new MaterialData();
        newData.data_key = e.target.name;
        newData.data_column = e.target.value;

        switch (newData.data_key) {
            case 'name':
                setDataName(newData);
                break;
            case 'number':
                setDataNumber(newData);
                break;
        }
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let newData = new MaterialData();
        newData.data_key = e.target.name;
        newData.data_column = e.target.value;

        if (newData.data_key === 'categorie') {
            setDataCategory(newData);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await updateOneMaterial(material).then((resp) => {
            if (resp.material_id !== 0) {
                if (material.data) {
                    for (let data of material.data) {
                        switch (data.data_key) {
                            case 'name':
                                data.data_column = dataName.data_column;
                                break;
                            case 'number':
                                data.data_column = dataNumber.data_column;
                                break;
                            case 'categorie':
                                data.data_column = dataCategory.data_column;
                                break;
                        }
                        if (data.materialData_id !== 0) {
                            updateOneMaterialData(data).then();
                        }else {
                            postDataMaterial(data).then();
                        }
                    }
                }
                toast.success('Matériel mis à jour avec succès', {});
                setTimeout(function() {
                    history.push(`/material`);
                }, 5000);
            }
            if (resp.error) {
                toast.warning('Echec modification', {});
            }
        } );
    }

    return (

        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            <div className="heroMaterial">
                <div id="materialFristDiv">
                    <div className="container mt-5 p-2">
                        <div className="mt-5 pt-5 mb-3 justify-content-center d-flex">
                            <div className="from" style={{background: 'white'}}>
                                <form onSubmit={e => handleSubmit(e)}>
                                    <h2 className="mb-5">Modifier le matériel</h2>
                                    <div className="form-group">
                                        <label htmlFor="name">Nom du Material</label>
                                        <input id="name" className="form-control" name="name"
                                               value={dataName.data_column} onChange={(e) => handleInputDataChange(e)}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="ref">Référence du Material</label>
                                        <input id="ref" className="form-control" name="ref"
                                               value={material.material_ref} onChange={(e) => handleInputChange(e)}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="number">Nombre du matériel</label>
                                        <input type="number" id="number" className="form-control" name="number"
                                               value={dataNumber.data_column}
                                               onChange={(e) => handleInputDataChange(e)}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="categorie">Catégorie</label>
                                        <select className="form-control" id="categorie" name="categorie"
                                                value={dataCategory.data_column}
                                                onChange={(e) => handleSelectChange(e)}>
                                            <option value="0">Veuillez choisir une Catégorie</option>
                                            {MaterialCategoryUtils.getCategoriesArray().map((category) => (
                                                <option value={category} key={category}
                                                        selected={Number(dataCategory.data_column) === category}>
                                                    {MaterialCategoryUtils.getTextCategory(category)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <Button variant="" type="submit" className="mt-3 btn btn-lg btnLogin">
                                        Modifier
                                    </Button>
                                </form>
                                <Link
                                    className="btn-floating btn-large waves-effect waves-light orange lighten-1 z-deepth-3"
                                    style={{position: 'fixed', top: '95px', left: '50px'}}
                                    to="/material">
                                    <i className="material-icons">navigate_before</i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UpdateMaterial;

