import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css';
import Fade from 'react-reveal/Fade';

const GrosOeuvre: FunctionComponent = () => {

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );   

    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">Le Gros Oeuvre</h1>
                <section className="mt-5 pt-5">
                    <div className="container">
                        <div className="row m-0 justify-content-center mb-5">
                            <div className="col-lg-4 align-items-center d-flex flex-column mb-3 mt-2">                            
                                <h4 className="d-flex justify-content-center mt-2">Le gros oeuvre: en quoi cela consiste ?</h4>
                                <p className="textAlign">Comme vous le savez, votre maison est soumise à plusieurs contraintes tels que les caprices de la météo, 
                                ou encore l’usure naturelle due au passage des années.
                                Pour y résister, il est nécessaire que ses fondations soient bien réalisées sa structure fiable, sa toiture étanche etc.
                                Tous les travaux qui visent à rendre un bâtiment solide et durable sont regroupés sous l’appellation « gros œuvre ».
                                Il s’agit principalement :
                                <ul>
                                    <li>- Des fondations</li>
                                    <li>- De l’édification des murs</li>
                                    <li>- De la mise en place de la charpente et de la toiture</li>
                                    <li>- De la pose des huisseries extérieures (portes et fenêtres)</li>
                                </ul>
                                Une fois le gros œuvre réalisé, on dit que votre maison est mise hors-eau et hors-air. 
                                C’est-à-dire qu’elle est étanche et ne craint donc plus ni l’humidité, ni les courants d’air.
                                En bref, elle n’est pas encore habitable mais le principal est fait !
                                Il ne reste plus que ce qu’on appelle « finitions » ou « travaux de second œuvre », c’est-à-dire la plomberie, 
                                l’électricité, la pose de revêtements, l’éventuelle construction d’escaliers…
                                Intéressons-nous maintenant en détails aux différents travaux faisant partie du gros œuvre.</p>                           
                            </div>
                            <Fade left>
                            <div className="col-6" id='ImgPageGrosOeuvre'></div>  
                            </Fade>
                        </div>

                        <ColoredLine color='orange' />

                        <div className="my-5 row m-0 justify-content-center">
                            <Fade right>
                            <div className="col-6" id='ImgPageGrosOeuvre2'></div>
                            </Fade>
                            <div className="col-lg-4 text-align-center align-items-center d-flex flex-column mb-3 mt-5">                           
                                <h4 className="d-flex justify-content-center mt-2">Prix du gros oeuvre</h4>
                                <p className="textAlign">Le prix du gros œuvre est de ~600 à 800€ par m², ce qui représente ~30 à 40% du montant total des travaux.
                                Le prix du gros œuvre évolue principalement en fonction des quatre paramètres suivants :
                                <ul>
                                    <li>- La portance du terrain qui détermine les fondations (terre-plein, vide sanitaire, fondation profonde) ;</li>
                                    <li>- Le nombre d’étages ;</li>
                                    <li>- Les huisseries à mettre en place (porte, fenêtre)</li>
                                    <li>- Le choix des matériaux (certains étant plus nobles et donc plus onéreux que d’autres).</li>
                                </ul>
                                </p>                            
                            </div>
                        </div>

                        <ColoredLine color='orange' />

                        <div className="my-5 row m-0 justify-content-center">
                            <div className="col-lg-4 text-align-center align-items-center d-flex flex-column mb-3 mt-5">                           
                                <h4 className="d-flex justify-content-center mt-2">Les travaux de gros oeuvre et leurs prix</h4>
                                <p className="textAlign">Que ce soit pour de la rénovation ou pour du neuf, cette activité nécessite une
                                    réactivité sans faille pour assurer des missions courtes,
                                    qui nous sont souvent confiées par les particuliers et les professionnels.
                                    La variété des missions « Travaux Services » qui nous sont confiées, est une preuve de
                                    nos compétences élargies. Des grands noms de l’industrie en demande de
                                    maintenance, aux particuliers en besoin d’une intervention de Remises en état suite à
                                    sinistre, notre champs de compétences est large, et maitrisé.
                                </p>
                            </div> 
                            <Fade left>
                            <div className="col-6" id='ImgPageGrosOeuvre3'>                                                       
                            </div>
                            </Fade>
                        </div>                    
                    </div>
                </section>                     
        </div>

        





    );
}

export default GrosOeuvre;