import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css'
import Fade from 'react-reveal/Fade';

const ServiceAdapteGenieCivil: FunctionComponent = () => {


    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">Ouvrage de génie civil</h1>           
                <section className="mt-5 pt-5">
                    <div className="container">
                        <div className="row m-0 justify-content-center mb-5">
                            <div className="col-lg-4 align-items-center d-flex flex-column mb-3 mt-2">                            
                                <p className="textAlign">Ce secteur comprend la construction de routes, Ponts, dalots, Réservoirs, 
                                châteaux d’eau, Silot de stockage, Assainissement et travaux urbains.
                                Heimdall construction exécute des ouvrages de grandes envergures tout en assurant un service de proximité pour des besoins 
                                spécifiques. Ses compétences dans ce domaine, acquises au cours de ses nombreuses années d’expériences, ainsi 
                                que la qualité de son personnel et de ses outils, permettent à Heimdall Construction d’offrir un service compétitif à ses donneurs d’ordre.</p>                           
                            </div>
                            <Fade left>
                            <div className="col-lg-6" id='ImgPageGenieCivil'></div> 
                            </Fade>           
                        </div>        
                    </div>
                </section>                     
        </div>
    );
}

export default ServiceAdapteGenieCivil;