class Url {

    // ARG ID
    static readonly ARG_SITE_ID = ':siteId';
    static readonly ARG_USER_ID = ':userId';
    static readonly ARG_ROLE_ID = ':roleId';
    static readonly ARG_MATERIAL_ID = ':materialId';
    static readonly ARG_TASK_ID = ':taskId';
    static readonly ARG_PROBLEM_ID = ':ticketId';
    static readonly ARG_DATA_ID = ':dataId';
    static readonly ARG_DATE = ':date';


    static readonly BASE_URL = 'http://api.heimdall.cda5.lh.manusien-ecolelamanu.fr/api/v1';
    // static readonly BASE_URL = 'http://heimdallapi/api/v1';

    // URL LOGIN
    static readonly POST_LOGIN_URL = Url.BASE_URL + '/login';

    // URL SITES
    static readonly GET_ALL_SITES_URL = Url.BASE_URL + '/sites';

    static readonly GET_SITE_SEARCH_URL = Url.GET_ALL_SITES_URL + '/search/' + Url.ARG_SITE_ID;
    static readonly GET_SITE_BY_USER_URL = Url.GET_ALL_SITES_URL + '/user/' + Url.ARG_USER_ID;

    static readonly POST_SITE_URL = Url.GET_ALL_SITES_URL;
    static readonly PUT_SITE_URL = Url.GET_ALL_SITES_URL + '/update';
    static readonly DELETE_SITE_URL = Url.GET_ALL_SITES_URL + '/delete/' + Url.ARG_SITE_ID;

    // URL SITES DATA
    static readonly GET_SITE_DATA_URL = Url.GET_ALL_SITES_URL + '/data/' + Url.ARG_SITE_ID;
    static readonly POST_SITE_DATA_URL = Url.BASE_URL + '/sites/data';
    static readonly PUT_SITE_DATA_URL = Url.BASE_URL + '/sites/data/update';
    static readonly DELETE_SITE_DATA_URL = Url.BASE_URL + '/sites/data/delete/' + Url.ARG_DATA_ID;

    // URL SITES date
    static readonly GET_SITE_DATE_URL = Url.GET_ALL_SITES_URL + '/sites/count/' + Url.ARG_DATE;

    // URL USERS
    static readonly GET_ALL_USERS_URL = Url.BASE_URL + '/users';
    static readonly GET_USER_SEARCH_URL = Url.BASE_URL + '/users/search/' + Url.ARG_USER_ID;
    static readonly GET_USERS_BY_ROLE_URL = Url.BASE_URL + '/users/role/' + Url.ARG_ROLE_ID;
    static readonly POST_USER_URL = Url.GET_ALL_USERS_URL;
    static readonly PUT_USER_URL = Url.GET_ALL_USERS_URL + '/update';
    static readonly DELETE_USER_URL = Url.GET_ALL_USERS_URL + '/delete/' + Url.ARG_USER_ID;

    // URL USERS DATA
    static readonly POST_USER_DATA_URL = Url.BASE_URL + '/users/data';
    static readonly PUT_USER_DATA_URL = Url.BASE_URL + '/users/data/update';
    static readonly DELETE_USER_DATA_URL = Url.BASE_URL + '/users/ data/delete/' + Url.ARG_DATA_ID;


    //URL PROBLEM
    static readonly DELETE_PROBLEM_URL = Url.BASE_URL + '/problems/delete/' + Url.ARG_PROBLEM_ID;
    static readonly GET_ALL_PROBLEM_URL = Url.BASE_URL + '/problems';
    static readonly GET_PROBLEM_URL = Url.BASE_URL + '/problems/'+Url.ARG_PROBLEM_ID;
    static readonly UPDATE_PROBLEM_URL = Url.BASE_URL + '/problems/update';
    static readonly POST_PROBLEM_URL = Url.BASE_URL + '/problems';

    //URL PROBLEM DATA
    static readonly DELETE_PROBLEM_DATA_URL = Url.BASE_URL + '/problems/data/delete/' + Url.ARG_PROBLEM_ID;
    static readonly GET_ALL_PROBLEM_DATA_URL = Url.BASE_URL + '/problems/data/all';
    static readonly GET_PROBLEM_DATA_URL = Url.BASE_URL + '/problems/data/problem/'+Url.ARG_PROBLEM_ID;
    static readonly GET_PROBLEM_DATA_BY_ID_URL = Url.BASE_URL + '/problems/data/'+Url.ARG_DATA_ID;
    static readonly UPDATE_PROBLEM_DATA_URL = Url.BASE_URL + '/problems/data/update';
    static readonly POST_PROBLEM_DATA_URL = Url.BASE_URL + '/problems/data';

    // URL MATERIALS
    static readonly GET_ALL_MATERIALS_URL = Url.BASE_URL + '/materials';
    static readonly GET_ALL_MATERIALS_CATEGORY_URL = Url.BASE_URL + '/materials/category/getAll';
    static readonly GET_MATERIAL_DETAIL_URL = Url.BASE_URL + '/materials/search/' + Url.ARG_MATERIAL_ID;
    static readonly PUT_MATERIAL_URL = Url.BASE_URL + '/materials/update';
    static readonly DELETE_MATERIAL_URL = Url.BASE_URL + '/materials/delete/' + Url.ARG_MATERIAL_ID;
    static readonly POST_MATERIAL_URL = Url.BASE_URL + '/materials';

    // URL MATERIALS DATA
    static readonly POST_MATERIAL_DATA_URL = Url.BASE_URL + '/materials/data';
    static readonly PUT_MATERIAL_DATA_URL = Url.BASE_URL + '/materials/data/update';

    // URL TASKS
    static readonly GET_ALL_TASKS_URL = Url.BASE_URL + '/plannings/searchAllTask';
    static readonly POST_TASK_URL = Url.BASE_URL + '/plannings/nativeTask';
    static readonly PUT_TASK_URL = Url.BASE_URL + '/plannings/tasks';

    // URL TASKS DATA
    static readonly GET_TASK_DATA_URL = Url.BASE_URL + '/plannings/tasks/data/' + Url.ARG_TASK_ID;
    static readonly POST_TASK_DATA_URL = Url.BASE_URL + 'plannings/nativeData';
    static readonly PUT_TASK_DATA_URL = Url.BASE_URL + '/plannings/tasks/data';

}

export {Url};