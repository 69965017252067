import React, { FunctionComponent, useEffect, useState } from "react";
import ParametersHelper from "../../helpers/parameters-helper";
import Sites from "../../models/sites";
import { getOneProblem, updateOneDataProblem, updateOneProblem ,postDataProblem } from "../../services/problems-service";
import SitesService from '../../services/sites-service';
import Problem from "../../models/Problem";
import { RouteComponentProps } from "react-router-dom";
import ProblemData from "../../models/ProblemData";
import { BrowserRouter , Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router';

type Params = { id: string };

const UpdateProblem: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {

    
    const [ref, setRef] = useState("");
    const [name, setName] = useState<ProblemData>(new ProblemData());
    const [site, setSite] = useState<ProblemData>(new ProblemData());
    const [description, setDescription] = useState<ProblemData>(new ProblemData());
    const [problem, setProblem] = useState(new Problem());

    const [allSites, setAllSite] = useState<Sites[]>([]);
    const history = useHistory();
    useEffect(() => {

        const getDataValue = (datas: ProblemData[]) => {
            if (datas.length !== 0) {
                for (const data of datas) {
                    if (data.problemData_key === 'Nom') {
                        setName(data);
                    }
                    if (data.problemData_key === 'Description') {
                        setDescription(data);
                    }
                    if (data.problemData_key === 'Site') {
                        setSite(data);
                    }
                }
            } else {
                name.problemData_problemId = problem.problem_id;
                setName(name)
                description.problemData_problemId = problem.problem_id;
                setDescription(description);
                site.problemData_problemId = problem.problem_id;
                setSite(site);
                datas.push(name);
                datas.push(description);
                datas.push(site)
            }
            
        }
        async function yes() {
            await SitesService.getAllSites().then(tt => setAllSite(tt));
            await getOneProblem(parseInt(match.params.id)).then(tt => setProblem(tt));
        }
        yes();
        getOneProblem(+match.params.id).then((e) => {
            setProblem(e);
            setRef(e.problem_ref)
            if (e.data) {
                getDataValue(e.data);
            }
        });
    }, [match.params.id]);

    const handleInputDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = new ProblemData();
        newData.problemData_key = e.target.name;
        newData.problemData_column = e.target.value;
        if(newData.problemData_key === "name") {
            setName(newData);
        }
        if(e.target.name === "ref")
        {
            setRef(e.target.value);
        }
    }
    const handleInputDataChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let newData = new ProblemData();
        newData.problemData_key = e.target.name;
        newData.problemData_column = e.target.value;
        if(newData.problemData_key === "Site") {
            setSite(newData);
        }
    }
    const handleInputDataChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let newData = new ProblemData();
        newData.problemData_key = e.target.name;
        newData.problemData_column = e.target.value;
        if(newData.problemData_key === "Description") {
                setDescription(newData);   
        }
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
            var problemToPost: Problem = new Problem();
            var dataToPost: ProblemData = new ProblemData();

            problemToPost.problem_id = parseInt(match.params.id);
            problemToPost.problem_ref = ref;
            await updateOneProblem(problemToPost).then(e => e);

            dataToPost.problemData_id = name.problemData_id;
            if(dataToPost.problemData_id === -1)
            {
                dataToPost.problemData_key = "Nom";
                dataToPost.problemData_column = name.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await postDataProblem(dataToPost).then(e => e);
            }else
            {
                dataToPost.problemData_key = "Nom";
                dataToPost.problemData_column = name.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await updateOneDataProblem(dataToPost).then(e => e);
            }
            dataToPost.problemData_id = site.problemData_id;        
            if(dataToPost.problemData_id === -1)
            {
                dataToPost.problemData_key = "Site";
                dataToPost.problemData_column = site.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await postDataProblem(dataToPost).then(e => e);
            }
            else{
                dataToPost.problemData_key = "Site";
                dataToPost.problemData_column = site.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await updateOneDataProblem(dataToPost).then(e => e);
            }
            dataToPost.problemData_id = description.problemData_id;           
            if(dataToPost.problemData_id === -1)
            {
                dataToPost.problemData_key = "Description";
                dataToPost.problemData_column = description.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await postDataProblem(dataToPost).then(e => e);           

            }
            else{ 
                dataToPost.problemData_key = "Description";
                dataToPost.problemData_column = description.problemData_column;
                dataToPost.problemData_problemId = parseInt(match.params.id);
                await updateOneDataProblem(dataToPost).then(e => e);
            }         
                        toast.success('Ticket mis à jours avec succès', {});
                        setTimeout(function() {
                            history.push(`/problems`);
                        }, 5000);
                }
    return (
        <div className="hero-auto">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="dark-toast"
                theme="dark" />

            <div className='heroProblemeEnfant'>
                <div className="container mt-5 pt-5 justify-content-center d-flex">
                    <form className="mt-5 " onSubmit={e => handleSubmit(e)}>
                        <div className="row">
                            <div className="col s12 m8">
                                <div className="card hoverable" id="cardGeneral">
                                    <h2 className="text-center mb-4"> Modifier un Ticket</h2>
                                    <div className="card-stacked p-0">
                                        <div className="card-content">
                                            <div className="form-group">
                                                <label htmlFor="name" className="text-secondary">Nom du Ticket</label>
                                                        <input id="name" className="form-control" name="name" value={name.problemData_column}onChange={(e) => handleInputDataChange(e)} />                                        
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="ref" className="text-secondary">Référence du Ticket</label>
                                                <input id="budget" type="number" className="form-control" name="ref" value={ref} onChange={(e) => handleInputDataChange(e)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="firstname" className="text-secondary">Site associés</label>
                                                <select id="firstname" name="Site" value={site.problemData_column} onChange={(e) => handleInputDataChangeSelect(e)}>
                                                    {allSites && allSites.map((e) => (

                                                        <option selected={e.site_id === Number(site.problemData_column)} value={e.site_id}>{e.site_id}</option>
                                                    ))}
                                                </select>


                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="desc" className="text-secondary">Description du Ticket</label>
                                                
                                                        <textarea id="desc" className="form-control styleArea" name="Description" value={description.problemData_column} onChange={(e) => handleInputDataChangeText(e)}>
                                                </textarea>
                                                   

                                               
                                            </div>
                                            <div className="card-action center">
                                                <button type="submit" className="btn btn-lg btnLogin">Valider</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Link className="btn-floating btn-large waves-effect waves-light orange lighten-1 z-deepth-3"
                style={{ position: 'fixed', top: '95px', left: '50px' }}
                to="/problems">
                <i className="material-icons">navigate_before</i>
            </Link>
        </div>

    );
}

export default UpdateProblem;
