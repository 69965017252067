export default  class SitesData {
    siteData_id: number = 0;
    siteData_siteId: number = 0;
    siteData_key: string = '';
    siteData_column: string = '';

    constructor(
        siteData?: SitesData
    ) {
        if (siteData) {
            this.siteData_id = siteData.siteData_id;
            this.siteData_siteId = siteData.siteData_siteId;
            this.siteData_key = siteData.siteData_key;
            this.siteData_column = siteData.siteData_column;
        }
    }
}
