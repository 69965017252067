import moment from "moment";
import Users from "../models/Users";

export const TokenValidity = (): boolean => {
    let validToken = moment().unix() < Number(localStorage.getItem('token_validity'));
    if (!validToken) {
        localStorage.clear();
    }
    return validToken;
}

export const deleteSession = () => {
    localStorage.clear();
}

export const Session = (): Users => {
    let data = localStorage.getItem('user');
    let myUser = new Users();
    if (data !== null) {
        myUser = Users.getUserFromJson(data);
    }
    return myUser;
}

export const generateHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': "bearer " + localStorage.getItem('token')
    }
}