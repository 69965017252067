export enum SiteType {
    UNDEFINED,
    ELECTRICITY,
    PLUMBING,
    ROOF,
    MASONRY,
    CARPENTRY,
    MAJOR_WORK
}

export class SiteTypeUtils {
    public static getSiteTypeArray(): SiteType[] {
        return [
            SiteType.ELECTRICITY,
            SiteType.PLUMBING,
            SiteType.ROOF,
            SiteType.MASONRY,
            SiteType.CARPENTRY,
            SiteType.MAJOR_WORK
        ];
    }

    public static getTextSiteType(type: SiteType): string {
        switch (type) {
            case SiteType.UNDEFINED:
                return 'Non défini';
            case SiteType.ELECTRICITY:
                return 'Electricité';
            case SiteType.CARPENTRY:
                return 'Menuiserie';
            case SiteType.PLUMBING:
                return 'Plomberie';
            case SiteType.MASONRY:
                return 'Maçonnerie';
            case SiteType.ROOF:
                return 'Toiture';
            case SiteType.MAJOR_WORK:
                return 'Gros Oeuvre';
        }
    }
}