import React, {FunctionComponent} from 'react';
import '../components/grosOeuvre.css'
import Fade from 'react-reveal/Fade';

const ServiceAdapteMissionContr: FunctionComponent = () => {

     

    return (

        <div className="mb-5">
            <h1 className="text-center mt-5 pt-5 titleStyle text-dark borderStyle">Mission de contrôle</h1>           
                <section className="mt-5 pt-5">
                    <div className="container">
                        <div className="row m-0 justify-content-center mb-5">
                            <div className="col-lg-4 align-items-center d-flex flex-column mb-3 mt-2">                            
                                <p className="textAlign">Un défaut de conception ou d’exécution sur chantier peut avoir des 
                                conséquences plus ou moins graves sur la solidité du bâti, la sécurité des occupants, l’accessibilité aux personnes 
                                handicapées, sur le fonctionnement des installations techniques (installations électriques, plomberie, climatisation, etc…).
                                Pour prévenir ces aléas techniques susceptibles d’entrainer des défauts de conception et/ou de construction, SICAT BTP met à 
                                disposition des maitres 
                                d’ouvrages publics et privés son expertise technique acquise sur des missions de surveillance et de contrôle de travaux et 
                                d’ingénieur-conseil aussi bien 
                                France.
                                Notre méthodologie consiste à :
                                <ul>
                                    <li>• Mission de contrôle : Suivi de travaux (Contrôle technique, OPC, MOE)</li>
                                    <li>• Particuliers : assistance technique tout corps d’état du début du projet jusqu’à la livraison de l’ouvrage (opération clé en mains)</li>
                                    <li>• une Inspection des installations techniques de sécurité et de confort (sécurité incendie, climatisation, plomberie, etc.)</li>
                                </ul>
                                Nous nous engageons, à fournir des rapports avec reportage photographique à toutes les étapes du projet au client afin qu’il 
                                puisse matérialiser l’avancement de son projet.</p>                           
                            </div>
                            <Fade left>
                            <div className="col-6" id='ImgPageMissionControle'>                                                       
                            </div>
                            </Fade>
                        </div>    
                    </div>
                </section>                     
        </div>

        





    );
}

export default ServiceAdapteMissionContr;