import React, {FunctionComponent, useState} from "react";
import {postUser, postUserData} from "../../services/usersServices";
import Users from "../../models/Users";
import UsersData from "../../models/UsersData";
import {Link} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {JobEmployee, JobEmployeeUtils} from "../../models/enums/jobEmployee";
import {GetRole, Role} from "../../models/role";
import ParametersHelper from "../../helpers/parameters-helper";
import {useHistory} from "react-router";


const CreateUser: FunctionComponent = () => {
    const history = useHistory();
    const [lastname, setLastname] = useState("");

    const [firstname, setFirstname] = useState("");

    const [login, setLogin] = useState("");

    const [role, setRole] = useState<GetRole>(GetRole.UNDEFINED);
    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);

    const [errorLastname, setErrorLastname] = useState(false);
    const [errorFirstname, setErrorFirstname] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [userJob, setUserJob] = useState<UsersData>(new UsersData());
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [userEmail, setUserEmail] = useState<UsersData>(new UsersData());

    const [userPhoneNumber, setUserPhoneNumber] = useState<UsersData>(new UsersData());


    const handleSelectJobChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let newJob = new UsersData();
        newJob.userData_key = e.target.name;
        newJob.userData_column = e.target.value;
        setUserJob(newJob);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = new UsersData();
        newData.userData_key = e.target.name;
        newData.userData_column = e.target.value;

        switch (newData.userData_key) {
            case 'email':
                setUserEmail(newData);
                if (!ParametersHelper.isValideEmail(newData.userData_column)) {
                    setEmailError(true);
                } else {
                    setEmailError(false);
                }
                break;
            case 'phoneNumber':
                setUserPhoneNumber(newData);
                if (!ParametersHelper.isValideTel(newData.userData_column)) {
                    setPhoneError(true);
                } else {
                    setPhoneError(false);
                }
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!ParametersHelper.testLogin(login)) {
            setErrorLogin(true);
        } else {
            setErrorLogin(false);
        }
        if (!ParametersHelper.testString(firstname)) {
            setErrorFirstname(true);
        } else {
            setErrorFirstname(false);
        }
        if (!ParametersHelper.testString(lastname)) {
            setErrorLastname(true);
        } else {
            setErrorLastname(false);
        }
        if (!ParametersHelper.testPassword(password)) {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }
        if (role === GetRole.UNDEFINED) {
            userJob.userData_key = 'job';
            userJob.userData_column = JobEmployee.UNDEFINED.toString();
            setUserJob(userJob);
        }

        if (!ParametersHelper.isValideEmail(userEmail.userData_column)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        if (!ParametersHelper.isValideTel(userPhoneNumber.userData_column)) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }

        if (!(errorFirstname && errorLastname && errorLogin && errorPassword && emailError && phoneError)) {
             await handlePostUser().then();
        } else {
            toast.warn("Le formulaire n'est pas valide !👎", {});
        }
    }

    const handlePostUser = async () => {
        let userToPost: Users = new Users();
        userToPost.login = login;
        userToPost.role_id = role;
        userToPost.password = password;

        let userLastName = new UsersData();
        userLastName.userData_key = "Nom";
        userLastName.userData_column = lastname;

        let userFirstname = new UsersData();
        userFirstname.userData_key = "Prénom";
        userFirstname.userData_column = firstname;

        postUser(userToPost).then(async (user) => {
            if (user) {
                userLastName.userData_userId = user.id;
                await postUserData(userLastName).then();
                userFirstname.userData_userId = user.id;
                await postUserData(userFirstname).then();
                userJob.userData_userId = user.id;
                setUserJob(userJob);
                await postUserData(userJob).then();
                userPhoneNumber.userData_userId = user.id;
                setUserPhoneNumber(userPhoneNumber);
                await postUserData(userPhoneNumber);
                userEmail.userData_userId = user.id;
                setUserEmail(userEmail);
                await postUserData(userEmail).then((e) =>
                    toast.success('Client crée avec succès', {})
                );
                window.setTimeout(() => {
                    history.push('/profile');
                }, 3000);
            }
        });



    }

    return (
        <div className="hero-auto">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="dark-toast"
                theme="dark"/>

            <div id="heroProfileEnfant">
                <div className="container mt-5 pt-5 d-flex justify-content-center">
                    <form className="mt-5" onSubmit={e => handleSubmit(e)}>
                        <div className="row">
                            <div className="col m8">
                                <div className="card hoverable" id="cardGeneral">
                                    <h2 className="text-center mb-5"> Création Utilisateur</h2>
                                    <div className="card-stacked">
                                        <div className="card-content">
                                            <div className="form-group">
                                                <label htmlFor="lastname" className="text-dark ">Nom </label>
                                                <input id="lastname" type="text" className="form-control"
                                                       name="lastname" value={lastname}
                                                       onChange={(e) => setLastname(e.target.value)}/>
                                                {errorLastname ?
                                                    <div className="red accent-1">
                                                        Nom invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="firstname" className="text-dark ">Prénom</label>
                                                <input id="firstname" type="text" className="form-control"
                                                       name="firstname" value={firstname}
                                                       onChange={(e) => setFirstname(e.target.value)}/>
                                                {errorFirstname ?
                                                    <div className="red accent-1">
                                                        Prénom invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="login" className="text-dark ">Login</label>
                                                <input id="login" type="text" className="form-control" name="login"
                                                       value={login} onChange={(e) => setLogin(e.target.value)}/>
                                                {errorLogin ?
                                                    <div className="red accent-1">
                                                        Login invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password" className="text-dark">Mot de passe</label>
                                                <input id="password" type="password" className="form-control" name="ref"
                                                       value={password} onChange={(e) => setPassword(e.target.value)}/>
                                                {errorPassword ?
                                                    <div className="red accent-1">
                                                        Mot de passe invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>


                                            <div className="form-group">
                                                <label htmlFor="userRole" className="text-dark ">Rôle</label>
                                                <select id="userRole" name="userRole"
                                                        onChange={(e) => setRole(Number(e.target.value))}>
                                                    <option disabled={true} value="0" selected={true}>Sélectionner le
                                                        rôle
                                                    </option>
                                                    {Role.getRoleArray().map((roleUser) => (
                                                        <option value={roleUser} key={roleUser}>
                                                            {Role.getStringEnum(roleUser)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email" className="text-dark">Email</label>
                                                <input id="email" type="text" className="form-control"
                                                       name="email" value={userEmail.userData_column}
                                                       onChange={(e) => handleInputChange(e)}/>
                                                {emailError ?
                                                    <div className="red accent-1">
                                                        Adresse Email invalide. (example@example.com)
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber" className="text-dark ">N°Téléphone</label>
                                                <input id="phoneNumber" type="text" className="form-control"
                                                       name="phoneNumber" value={userPhoneNumber.userData_column}
                                                       onChange={(e) => handleInputChange(e)}/>
                                                {phoneError ?
                                                    <div className="red accent-1">
                                                        N°Téléphone invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            {role === GetRole.EMPLOYEE ?
                                                <div className="form-group">
                                                    <label htmlFor="job" className="text-dark ">Emploi</label>
                                                    <select id="job" name="job" className='form-control'
                                                            onChange={(e) => handleSelectJobChange(e)}>
                                                        <option disabled={true} value='0'>Sélectionner un emploi</option>
                                                        {JobEmployeeUtils.getJobArray().map((job) => (
                                                            <option value={job} key={job}>
                                                                {JobEmployeeUtils.getTextJob(job)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                :
                                                ''
                                            }
                                            <div className="card-action center">
                                                <button type="submit" className="btn btn-lg btnLogin">Valider</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Link className="btn-floating btn-large waves-effect waves-light orange lighten-1 z-deepth-3"
                  style={{position: 'fixed', top: '95px', left: '50px'}}
                  to="/profile">
                <i className="material-icons">navigate_before</i>
            </Link>

        </div>

    );

}

export default CreateUser;