import React, {FunctionComponent} from "react";
import Sites from "../../models/sites";
import Moment from 'moment';
import {Button, ProgressBar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import moment from "moment";
import SitesData from "../../models/sitesData";
import {SiteTypeUtils} from "../../models/enums/siteType";
import Fade from 'react-reveal/Fade';
type Props = {
    site: Sites
}

const SitesCard: FunctionComponent<Props> = ({site}) => {
    const history = useHistory();
    const goToSite = (id: number) => {
        history.push(`/sites/search/${id}`);
    }
    const goToEdit = (id: number) => {
        history.push(`/sites/edit/${id}`);
    }

    const setDates = (date: number) => {
        let myDate = Moment.unix(date);
        return myDate.format('DD/MM/YYYY');
    }

    const getDiffDate = () => {
        let start = moment.unix(site.site_date_start);
        let end = moment.unix(site.site_date_end);
        let now = moment();

        let siteNbDays = end.diff(start, 'days');
        let fromStart = now.diff(start, 'days');

        let percent = (fromStart / siteNbDays) * 100;

        if (percent !== 0) {
            return percent;
        } else {

            return 100;
        }
    }

    const getValueSiteType = (data: SitesData[]) => {
        let type = '';
        if (data) {
            for (const d of data) {
                if (d.siteData_key === 'type') {
                    type = SiteTypeUtils.getTextSiteType(Number(d.siteData_column));
                }
            }
        }
        return type;
    }

    const goToPlanning = (id: number) => {
        history.push(`/planningSite/uniq/` + id);
    }

    return (
        <div className="col-sm-12 col-md-6 col-lg-3 s6 m4">
            <Fade left>
            <div className="card-deck">
                <div className="card">
                    <img src="https://batiadvisor.fr/wp-content/uploads/2019/07/pgc-chantier.jpg" alt="Props"/>
                    <div className="card-body" style={{minHeight: '300px'}}>
                        <h5 className="card-title d-flex justify-content-center">{site.site_id}</h5>
                        <p className="card-id">Numéro de chantier : {site.site_number_site} </p>
                        {site.data ?
                            <p>{getValueSiteType(site.data) !== '' ? 'Type : ' + getValueSiteType(site.data) : ''}</p>
                            :
                            ''
                        }
                        <p className="card-date">Débute le : {setDates(site.site_date_start)}</p>
                        {
                            site.site_date_end !== null ? <p>Terminé le
                                    : {setDates(site.site_date_end)}</p> :
                                <p>En cours</p>
                        }
                        <ProgressBar animated now={getDiffDate()} variant={getDiffDate() === 100 ? 'success' : ''}/>
                        <div className="d-flex justify-content-center">
                            <Button variant="outline-warning" type="submit"
                                    className="mt-3 m-3 btn-floating btn-meduim waves-effect waves-light green z-deepth-3"
                                    onClick={() => goToSite(site.site_id)}>
                                <i className="material-icons">search</i>
                            </Button>
                            <Button variant="outline-warning" type="submit"
                                    className="mt-3 m-3 btn-floating btn-meduim waves-effect waves-light yellow z-deepth-3"
                                    onClick={() => goToEdit(site.site_id)}>
                                <i className="material-icons">edit</i>
                            </Button>
                            <Button variant="outline-warning" type="submit"
                                    className="mt-3 m-3 btn-floating btn-meduim waves-effect waves-light blue z-deepth-3"
                                    onClick={() => goToPlanning(site.site_id)}>
                                <i className="material-icons">event</i>
                            </Button>

                            {/* <Button className="m-2" variant="outline-warning" onClick={ () => goToSite(site.site_Id)} >Detail</Button>
                                <Button className="m-2" variant="outline-warning" onClick={ () => goToEdit(site.site_Id)} >Edit</Button>*/}
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
        </div>
    );
}

export default SitesCard;
