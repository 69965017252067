import UsersData from "./UsersData";
import {DateDetail} from "./utils/DateDetail";

export default class Users {
    id: number = 0;
    login: string = '';
    password: string = '';
    role_id: number = 0;
    created_at: DateDetail = new DateDetail();
    updated_at: DateDetail|null = null;
    deleted_at: DateDetail|null = null;
    data?: UsersData[]|null = null;

    constructor( user?: Users ) {
        if( user !== null && user !== undefined ) {
            this.id = user.id;
            this.login = user.login;
            this.password = user.password;
            this.role_id = user.role_id;
            this.created_at = user.created_at;
            this.updated_at = user.updated_at;
            this.deleted_at = user.deleted_at;
            this.data = user.data;
        }
    }

    static getUserFromJson(data: string): Users {
        return new Users(JSON.parse(data));
    }

}
