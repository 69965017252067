import React, {FunctionComponent, useEffect, useState} from "react";
import Users from "../../models/Users";
import UsersData from "../../models/UsersData";
import {
    getUserById, postUserData,
    updateUser,
    updateUserData
} from "../../services/usersServices";
import {RouteComponentProps} from "react-router-dom";
import {GetRole, Role} from "../../models/role";
import {BrowserRouter, Link} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router';
import {JobEmployee, JobEmployeeUtils} from "../../models/enums/jobEmployee";
import ParametersHelper from "../../helpers/parameters-helper";

type Params = { id: string };

type Field = {
    value?: any,
    error?: string,
    isValid?: boolean
}
type Form = {
    login: Field,
    role: Field
}

const UpdateUser: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
    const history = useHistory();


    const [errorLastname, setErrorLastname] = useState(false);
    const [errorFirstname, setErrorFirstname] = useState(false);    const [lastname, setLastname] = useState<UsersData>(new UsersData());
    const [firstname, setFirstname] = useState<UsersData>(new UsersData());
    const [email, setEmail] = useState<UsersData>(new UsersData());
    const [job, setJob] = useState<UsersData>(new UsersData());
    const [phoneNumber, setPhoneNumber] = useState<UsersData>(new UsersData());

    const [errorLogin, setErrorLogin] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const [formValide, setFormValide] = useState(false);

    const [user, setUser] = useState(new Users());

    useEffect(() => {

        const getDataValue = (datas: UsersData[]) => {
            if (datas.length !== 0) {
                for (const data of datas) {
                    switch (data.userData_key) {
                        case 'Nom':
                            setLastname(data);
                            break;
                        case 'Prénom':
                            setFirstname(data);
                            break;
                        case 'job':
                            setJob(data);
                            break;
                        case 'phoneNumber':
                            setPhoneNumber(data);
                            break;
                        case 'email':
                            setEmail(data);
                            break;
                        default:
                            break;
                    }
                }
            } else {

                email.userData_userId = user.id;
                email.userData_key = 'email';
                setEmail(email);

                job.userData_userId = user.id;
                job.userData_key = 'job';
                setJob(job);

                phoneNumber.userData_userId = user.id;
                phoneNumber.userData_key = 'phoneNumber';
                setPhoneNumber(phoneNumber);

                datas.push(email);
                datas.push(job);
                datas.push(phoneNumber);
            }
        }

        getUserById(+match.params.id).then((user) => {
            setUser(user);
            if (user.data) {
                getDataValue(user.data);
            }
        });
    }, [match.params.id]);

    const [form, setForm] = useState<Form>({
        login: {value: ''},
        role: {value: 0}
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;

        if (fieldName === 'login') {
            user.login = fieldValue;
        }
        const newField: Field = {[fieldName]: {value: fieldValue}};
        setForm({...form, ...newField});
    }

    const handleInputDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = new UsersData();
        newData.userData_key = e.target.name;
        newData.userData_column = e.target.value;
        switch (newData.userData_key) {
            case 'Nom':
                setLastname(newData);
                user.data!.map((data) => data.userData_key === 'Nom' ? data.userData_column = newData.userData_column : '');
                break;
            case 'Prénom':
                setFirstname(newData);
                user.data!.map((data) => data.userData_key === 'Prénom' ? data.userData_column = newData.userData_column : '');
                break;
            case 'phoneNumber':
                setPhoneNumber(newData);
                user.data!.map((data) => data.userData_key === 'phoneNumber' ? data.userData_column = newData.userData_column : '');
                break;
            case 'email':
                setEmail(newData);
                user.data!.map((data) => data.userData_key === 'email' ? data.userData_column = newData.userData_column : '');
                break;
        }
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        if (fieldName === 'role') {
            user.role_id = +fieldValue;
        }
        const newField: Field = {[fieldName]: {value: fieldValue}};
        setForm({...form, ...newField});
    }

    const handleSelectJobChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let newData = new UsersData();
        newData.userData_key = e.target.name;
        newData.userData_column = e.target.value;
        if (newData.userData_key === 'job') {
            setJob(newData);
            user.data!.map((data) => data.userData_key === 'job' ? data.userData_column = newData.userData_column : '');
        }
    }

    const validateForm = () => {
        let valide = false;
        if (user.data)  {
            for (const data of user.data) {
                switch (data.userData_key) {
                    case 'Nom':
                        if (!ParametersHelper.testString(data.userData_column)) {
                            setErrorLastname(true);
                        } else {
                            setErrorLastname(false);
                        }
                        break;
                    case 'Prénom':
                        if (!ParametersHelper.testString(data.userData_column)) {
                            setErrorFirstname(true);
                        } else {
                            setErrorFirstname(false);
                        }
                        break;
                    case 'email':
                        if (!ParametersHelper.isValideEmail(data.userData_column)) {
                            setEmailError(true);
                        } else {
                            setEmailError(false);
                        }
                        break;
                    case 'phoneNumber':
                        if (!ParametersHelper.isValideTel(data.userData_column)) {
                            setPhoneError(true);
                        } else {
                            setPhoneError(false);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        if (errorLogin){
            valide = errorLogin;
        }
        if (errorFirstname){
            valide = errorFirstname;
        }
        if (errorLastname){
            valide = errorLastname;
        }
        if (emailError) {
            valide = emailError;
        }
        if (phoneError){
            valide = phoneError;
        }

        return !valide;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            putUser(user).then();
        }
    }

    const putUser = async (user: Users) => {
        if (validateForm()) {
            await updateUser(user).then();

            if (user.data) {
                for (let data of user.data) {
                    switch (data.userData_key) {
                        case 'Nom':
                            data.userData_column = lastname.userData_column;
                            break;
                        case 'Prénom':
                            data.userData_column = firstname.userData_column;
                            break;
                        case 'email':
                            data.userData_column = email.userData_column;
                            break;
                        case 'job':
                            data.userData_column = job.userData_column;
                            break;
                        case 'phoneNumber':
                            data.userData_column = phoneNumber.userData_column;
                            break;
                    }
                    if (data.userData_id !== 0) {
                        await updateUserData(data).then();
                    } else {
                        await postUserData(data).then();
                    }
                }
                toast.success('Profil modifier avec succès', {});
            }
            window.setTimeout(() => {
                history.push('/profile');
            }, 3000);
        }
    }

    return (
        <div className="hero-auto">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="dark-toast"
                theme="dark"/>
            <div id='heroProfileEnfant'>
                <div className="container mt-5 pt-5 justify-content-center d-flex align-items-center h-100">
                    <form className="mt-5" onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col m8">
                                <div className="card hoverable" id="cardGeneral">
                                    <h2 className="text-center mb-5 mt-2"> Modifier un utilisateur</h2>
                                    <div className="card-stacked">
                                        <div className="card-content p-0">
                                            <div className="form-group">
                                                <label htmlFor="lastname" className="text-secondary">Nom </label>
                                                <input id="lastname" type="text" className="form-control" name="Nom"
                                                       value={lastname.userData_column}
                                                       onChange={(e) => handleInputDataChange(e)}/>
                                                {errorLastname ?
                                                    <div className="red accent-1">
                                                        Nom invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="firstname" className="text-secondary">Prénom</label>
                                                <input id="firstname" type="text" className="form-control" name="Prénom"
                                                       value={firstname.userData_column}
                                                       onChange={(e) => handleInputDataChange(e)}/>
                                                {errorFirstname ?
                                                    <div className="red accent-1">
                                                        Prénom invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="login" className="text-secondary">Login</label>
                                                <input type="text" className="form-control" name="login"
                                                       value={user.login} onChange={(e) => handleInputChange(e)}/>
                                                {errorLogin ?
                                                    <div className="red accent-1">
                                                        Login invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email" className="text-dark">Email</label>
                                                <input id="email" type="text" className="form-control"
                                                       name="email" value={email.userData_column}
                                                       onChange={(e) => handleInputDataChange(e)}/>
                                                {emailError ?
                                                    <div className="red accent-1">
                                                        Adresse Email invalide. (example@example.com)
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber" className="text-dark ">N°Téléphone</label>
                                                <input id="phoneNumber" type="text" className="form-control"
                                                       name="phoneNumber" value={phoneNumber.userData_column}
                                                       onChange={(e) => handleInputDataChange(e)}/>
                                                {phoneError ?
                                                    <div className="red accent-1">
                                                        N°Téléphone invalide.
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="role" className="text-secondary">Rôle</label>
                                                <select id="role" value={user.role_id} name="role"
                                                        onChange={(e) => handleSelectChange(e)}>
                                                    {Role.getRoleArray().map((role) => (
                                                        <option key={role} value={role}
                                                                selected={user.role_id === role}>
                                                            {Role.getStringEnum(role)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {user.role_id === GetRole.EMPLOYEE ?
                                                <div className="form-group">
                                                    <label htmlFor="job" className="text-dark ">Emploi</label>
                                                    <select id="job" name="job" className='form-control' value={job.userData_column}
                                                            onChange={(e) => handleSelectJobChange(e)}>
                                                        <option value='0'>Sélectionner un emploi</option>
                                                        {JobEmployeeUtils.getJobArray().map((jobEmp) => (
                                                            <option value={jobEmp} key={jobEmp} selected={jobEmp === Number(job.userData_column)}>
                                                                {JobEmployeeUtils.getTextJob(jobEmp)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                :
                                                ''
                                            }
                                            <div className="card-action center">
                                                <button type="submit" className="btn btn-lg btnLogin">Valider</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Link className="btn-floating btn-large waves-effect waves-light orange lighten-1 z-deepth-3"
                          style={{position: 'fixed', top: '95px', left: '50px'}}
                          to="/profile">
                        <i className="material-icons">navigate_before</i>
                    </Link>
                </div>
            </div>
        </div>

    );
}

export default UpdateUser;
